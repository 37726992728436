const terms_approval:{ [key: string]: string }  = {
    billagan_terms_approval:`תקנון אתר afternoon.billagan לגביית תשלומי הורים בצהרונים
            <br><br>
            מבוא – 
            <br><br>
            ברוכים הבאים לאתר https://afternoon.billagan.co.il (להלן: "האתר") המופעל על ידי ח.צ טכנולוגיות בע"מ, ח.פ 516540457, מכתובת הבע"שט 6 לוד (להלן: "הנהלת האתר" ו/או "החברה").
            <br><br>
            כל שימוש באתר מהווה הסכמה לתקנון זה, על התנאים וההגבלות הכלולים בו.
            <br><br>
            התקנון עלול להשתנות מעת לעת, ועל המשתמש להתעדכן בתקנון בכל כניסה לאתר. גלישה באתר ו/או שימוש בו מכל סוג, כמוהם כהסכמה לתנאי התקנון והתחייבות לפעול על פיהם. מובהר כי התקנון מהווה הסכם משפטית מחייבת לכל דבר ועניין ומחייב את המשתמש על כל שימושיו באתר.
            <br>
            האמור בתקנון זה בלשון זכר הוא לשם הנוחות בלבד והתקנון מתייחס לבני שני המינים באופן שווה.
            השירותים באמצעות האתר כפופים לתנאים המפורטים בתקנון זה.
            עצם הזמנת שירותים באתר תהווה הצהרה מצד הלקוח כי קרא את הוראות תקנון זה, הבין אותן והסכים להן. התקנון מהווה חוזה מחייב בין המשתמש לבין הנהלת האתר.
            <br><br>
            השירותים – 
            <br>
            האתר מאפשר שירות גביית תשלומי הורים לצהרונים
            ניתן לשלם עבור שירותי האתר באמצעות כרטיס אשראי בכפוף לאישור הנהלת האתר.
            מחירי השירותים הינם כפי שנקבעו ע"י הרשות המקומית בהתאם לחוזר מנכ"ל משרד החינוך בענין תשלומי הורים.
            קבלה בגין התשלום תשלח באופן אוטמטי בסמוך לאישור התשלום שבוצע באתר. בכל בעיה או בקשה בנושא אסמכתאות התשלום, ניתן לפנות לשירות הלקוחות של חברת ח.צ טכנולוגיות בע"מ במייל office@htz-tech.co.il  או בטלפון: 0585511320
            הנהלת האתר שומרת לעצמה את הזכות להפסיק את שירותיה בכל עת.
            הזכאות לשימוש באתר הינה לכל אזרח אשר מלאו לו 18 שנים. הזמנה תתאפשר למי שברשותו חשבון בנק פעיל ו/או בעל כרטיס אשראי ישראלי תקף והינו בעל תיבת דואר אלקטרוני אשר כתובתה תינתן בעת ביצוע התשלום באתר. קטין המבצע פעולות ייחשב כקטין אשר קיבל את רשות הוריו/אפוטרופוסיו לביצוע התשלום באתר.
            <br><br>
            ביטולים ושינוי סוגי השירותים – 
            <br>
            מדיניות ביטול עסקה והחזרת התשלום, בהתאם למדיניות הרשות המקומית תתבצע במידה והילד נשוא התשלום עזב את מוסד הלימודים עבורו שילם.
            ההחזר יינתן החל מהחודש העוקב לתאריך העזיבה ומותנה בהודעה על העזיבה לשירות הלקוחות של חברת ח.צ טכנולוגיות בע"מ במייל office@htz-tech.co.il  או בטלפון לשירות הלקוחות: 0585511320
            <br><br>
            זמינות ומיקום אזורי – 
            <br>
            בכל שאלה או בירור לגבי השירותים ניתן לחייג אל שירות הלקוחות של ח.צ טכנולוגיות בע"מ בטלפון 0585511320
            <br><br>
            אחריות ושירות – 
            <br>
            השירותים יינתנו בצהרונים בהתאם לדרישות הרשות המקומית ועל אחריותה.
            אורך השירות הינו ע"פ מועד הזמן אשר נקבע מראש ע"י הרשות המקומית.
            הנהלת האתר אינה אחראית על נזקים פיזיים או כספיים או כל נזק אחר שנגרמו במהלך השירות .
            הנהלת האתר מסירה מעליה כל אחריות מהתכנים הנכתבים באתר או מהשימוש בהם והשלכותיהם.
            <br><br>
            אבטחת מידע ופרטיות – 
            <br>
            הנהלת האתר, עושה כל שביכולתה בכדי לשמור על סודיות הלקוח ומתחייבים לא להעביר את פרטי האשראי לצד ג' אלא בהוראת בית דין. דף התשלום עומד בתקן אבטחה PCI – DSS
            באתר לא נאסף מידע על הלקוח למעט אמצעי התשלום איתו משולם השירות.
            תוכן השירות וחשיפת מידע אישי במהלך השירות, לעולם לא ייחשפו לאדם אחר או כל גורם ללא אישור בכתב מהלקוח. הנהלת האתר מחויבת לפרטיות שלך ופרטיות זו הינה בראש סדר העדיפויות שלנו.
            החברה מתחייבת שלא לעשות שימוש במידע המסופק לה ע"י הקונים אלא על מנת לאפשר את הרכישה באתר התשלום ובהתאם לכל דין.
            באמצעות לחיצה על הכפתור אני מאשר בחתימתי את הטופס ואת תנאיו.
            האתר מאובטח באמצעות שימוש באמצעי אבטחה מתקדמים אשר מטרתם להבטיח שימוש תקין וגלישה בטוחה באתר וכן על מנת לשמור על פרטיות המשתמשים. כל המשתמש באתר ובשירותיו מתחייב כי לא יעשה כל פעילות שיש בה כדי לשבש את פעילות האתר, גניבת מידע על משתמשים ופריצה של מנגנוני האבטחה של האתר.
            במקרה של שימוש לרעה, הנהלת האתר תפעל נגד כל פעילות בכל דרך חוקית אשר תעמוד לרשותה לרבות חסימת המשתמש מגישה לאתר ונקיטת הליכים משפטיים נגד המשתמש באם יפעל כאמור.
            <br><br>
            קניין רוחני – 
            <br>
            כל זכויות הקניין הרוחני באתר זה הכוללים זכויות יוצרים, זכויות הפצה, סודות מסחריים, סימני המסחר וכל הקניין הרוחני מכל סוג שהוא, הן בנוגע לעיצוב ועמוד האתר, הן באשר לתכנים המופיעים בו הינן רכושה הבלעדי של הנהלת האתר.
            אין להעתיק, לשכפל, להפיץ, לפרסם או להשתמש בכל דרך אחרת במידע כלשהו מן האתר ו/או מהאתר, אלא אם ניתנה הסכמה לכך מראש ובכתב מטעם הנהלת האתר.
            <br>
            דין וסמכות שיפוט –
            <br><br>
            פרשנותו ואכיפתו של תקנון זה ו/או כל פעולה או סכסוך הנובע ממנו יעשו בהתאם לדין הישראלי בלבד ולבית המשפט המוסמך בלוד תהא מסורה סמכות השיפוט הבלעדית.
            <br><br>
            יצירת קשר – 
            <br>
            לכל שאלה ופנייה ניתן ליצור קשר עם הנהלת האתר בטלפון 0585511320 או במייל office@htz-tech.co.il   בשעות הפעילות של מוקד שירות הלקוחות א'-ה' בין השעות 9:00-15:00.
            `,
eliad_general_terms_approval:`כתב התחייבות צהרון עמותת אליעד (להלן: "העמותה")
            <br><br>    
            בתי ספר/גני ילדים - תשפ"ה
            <br><br>
            מתחייבים בזאת ביחד ולחוד כלפי הנהלת העמותה כדלקמן:
            <br><br>
            1.	לשלם את שכר הלימוד החודשי (להלן: "שכר הלימוד").
            <br><br>
            2.	לשלם במועד שייקבע על-ידי הנהלת העמותה את שכר הלימוד עבור הילד/ה.
            <br><br>
            3.	אנו מתחייבים לשלם את שכר הלימוד החל מחודש הצטרפות הילד לצהרון באמצעות הוראת קבע בנקאית.
            <br><br>
            4.	אנו מתחייבים בזאת שלא לבטל את אמצעי התשלום עד לסיום תשלום מלוא שכר הלימוד.
            <br><br>
            5.	בכל מקרה של פיגור בתשלום סכום כלשהו עפ"י כתב התחייבות זה העולה על שבעה ימים, אנו מתחייבים לשלם לכם הפרשי הצמדה וריבת פיגורים בשיעור הנהוג בבנה"פ על חריגות בחשבונות עו"ש דביטורי וזאת מבלי לגרוע מיתר סעדי עפ"י כתב  התחייבות ו\או עפ"י כל דין. 
            <br><br>
            6.	בנוסף לאמור בסעיף לעיל, אנו מתחייבים לשלם להנהלת העמותה, את כל ההוצאות בקשר לאי תשלום סכום כלשהו עפ"י כתב התחייבות זה במלואו ובמועדו כולל שכ"ט עו"ד. בגין ביטול הוראה קבע נחויב  בסך של 68 ₪,  בגין ביטול חוזר של הוראת קבע נחויב בסך של 100 ₪.
            <br><br>
            7.	הייתה גבייתו של תשלום כלשהו המגיע להנהלת העמותה כרוכה בנקיטת הליכים משפטיים, אנו מתחייבים לשלם את הוצאות המשפט ושכ"ט עו"ד, כפי שיפסקו בפסק דין. 
            <br><br>
            8.	ידוע לנו כי הנהלת הצהרון תהא רשאית להפסיק ביקורו של ילד בצהרון בכל מקרה של פיגור בתשלום כלשהו, עפ"י כתב התחייבות זה וזאת לאחר, מתן התראה בכתב של 14 יום מראש, במהלכם לא נפרע חוב שכר הלימוד, והכל מבלי לגרוע מיתר סעדיה עפ"י כתב ההתחייבות ועפ"י כל דין.
            <br><br>
            עוד הוסכם בין הצדדים כדלקמן :
            <br><br>
            9.	העמותה מקבלת על עצמה בזה להפעיל את הצהרון וההורים מתחייבים לקבל עליהם להסכים לתנאים להפעלתו. 
            <br><br>
            10.	הצהרונים יפעלו בגני הילדים/בתי הספר של היישוב/המועצה.
            <br><br>
            11.	העמותה תהיה מוסמכת לקבל כספים עבור הצהרון על מנת לבצע את כל הפעולות האדמיניסטרטיביות של הצהרון, להחליט על אופן הפעלתו, פתיחתו, סגירתו ו/או הפסקת השתתפות של מי מהילדים בו.
            <br><br>
            12.	ההורים מאשרים לעמותה לצלם את הילד ולפרסם את תמונתו בקבוצות ובאתר העמותה.
            <br><br>
            13.	זמני פעילות הצהרון: 
            <br><br>
            א.	הצהרון ייפתח החל מיום ה- 01.09.2024 ועד ליום ה - 30.06.2025.
            <br><br>
            ב.	הצהרון יפעל בימים א'–ה'.
            <br><br>
            ג.	לילדי ט"ט-חובה, פעילות הצהרון תחל לאחר ימי ההסתגלות. למען התנהלות תקינה בצהרון, אין אפשרות לנוכחות הורים בצהרון.
            <br><br>
            ד.	במהלך השנה אפשר להצטרף על בסיס מקום פנוי ב-1 בחודש או ב-15 בחודש. 
            <br><br>

            14.	בחופשות של משרד החינוך, תתקיים הפעילות גם בשעות הבוקר
            שעות הפעילות בימים אלו: 8:00-16:00.
            א.	בימי חנוכה ופסח. 
            ב.	עבור ימים אלו תתבצע גבייה נוספת. סכום הגבייה יעודכן סמוך למועד הפעילות. 
            <br><br>
            15.	הצהרון לא יפעל בימים שיש בהם מסיבה בגן, אבל לא יותר משתי מסיבות בשנה.
            <br><br>
            16.	בימים בהם גן הבוקר מסתיים מוקדם, הצהרון יחל משעת סיום הגן ויסתיים מוקדם יותר בהתאמה.
            <br><br>
            17.	בימי שביתה לא מתקיים צהרון.
            <br><br>
            18.	במקרה של ביטול הצהרון עקב הנחיות פיקוד העורף/ הרשות - לא יינתן זיכוי.
            <br><br>
            19.	הצהרון כולל ארוחת צהרים חמה ביתית, הצהרון אינו מתחייב לסידורי דיאטה מיוחדים. (אין להביא ארוחת צהרים מהבית, למעט באם יש סיבה רפואית ומוכחת באישור רפואי מתאים). 
            <br><br>
            20.	מתקיימת העשרה, ותוכנית פדגוגית חודשית. 
            <br><br>
            21.	עלות הצהרון: 
            א.	עלות  הצהרון {fieldValue} ₪ לחודש. 
            <br><br>
            ב.	ההורים מתחייבים להסדיר את תשלום דמי ההשתתפות בצהרון בעת ההרשמה, מראש עבור כל השנה ב-10 תשלומים חודשיים כמקובל בצהרונים. לא יתבצע רישום ללא הסדרת תשלום מראש לכל השנה. 
            <br><br>
            22.	רישום מאוחר בהתאם לאמור בסעיף 13(ד) לעיל – ייגבה מההורים מחצית מהסכום האמור בסעיף 21(א) באם הרישום היה בתאריך 15 לחודש.
            <br><br>
            23.	ההורים מאשרים ויתור סודיות מול צוות הבוקר בנוגע לילדם. 
            <br><br>
            24.	השתתפות ילד אלרגי, שיש לו סייעת צמודה בבוקר, הינה באישור העמותה בלבד. לקבלת האישור יש להגיש בקשה בכתב.
            <br><br>
            25.	ילד חולה – ההורים מתחייבים לא לשלוח ילד חולה לצהרון. 
            <br><br>
            26.	לא תתקבל טענה להחזר כספים בגין ימי מחלה ו\או עקב אירוע משפחתי ו\או כל ביטול הצהרון מסיבות שאינן תלויות בעמותה.
            <br><br>
            27.	לעמותה יש את הזכות להוציא ילד מהצהרון אם ישנה או תתעורר בעיית גמילה חריגה. 
            <br><br>
            28.	ההורים מתחייבים להוציא את הילדים מהפעילות בשעה המיועדת. כל איחור יגרום לתוספת תשלום, על כל איחור של חמש דקות מעבר לשעת הסגירה יגבו 25 ₪  בתוספת לשכר הלימוד החודשי ויועברו ע"י העמותה לצוות באופן אוטומטי.
            <br><br>
            נוהלי ביטול הרשמה בצהרון:
            29.	ביטול הצהרון יתאפשר רק בהודעה מראש בשליחת מייל לכתובת הבאה:
            office@htz-tech.co.il .יש ליידע מראש את רכז הצהרונים באזור, תוך פירוט הסיבות והנימוקים לבקשה. 
            <br><br>
            30.	יש לוודא קבלת הבקשה. לא תתקבלנה בקשות ביטול בכל דרך אחרת לרבות בטלפון.
            <br><br>
            31.	יובהר, כי שליחת המייל אינה עילה לביטול הרישום, ורק תשובה חיובית מהעמותה הינה הוכחה לביטול הרישום. 
            <br><br>
            32.	במידה והבקשה תאושר הודעת ביטול תיכנס לתוקף לצורך ביטול עלות הצהרון כלהלן:
            א.	באם הודעת הביטול תימסר בעמותה עד ה-15 בחודש, חיוב תשלום האחרון יהיה עבור אותו חודש. 
            ב.	באם הודעת הביטול תימסר לעמותה לאחר ה-15 בחודש, חיוב התשלום האחרון יהיה עבור החודש העוקב לאותו החודש בו ניתנה הודעת העזיבה (כך לדוג', הודעה שנמסרה ב – 16.9, יחויבו ההורים עבור חודש ספטמבר וגם עבור חודש אוקטובר). 
            ג.	תינתן אפשרות להשתתף בפעילות הצהרון עד תום מועד התשלום.
            33.	החל מ- 30.3.2025 ואילך לא יאושרו ביטולים או החזרים מכל סיבה שהיא, והמשתתף יחויב בעלות הצהרון עד סוף חודש יוני. 
            <br><br>
            34.	העמותה שומרת לעצמה את הזכות להוציא ילד מפעילות עקב התנהגות לא הולמת או אי עמידה בתשלומים.
            <br><br>

            • קיום הצהרון מותנה במינימום נרשמים.
            <br><br>
            • ייתכן וברישום מינימום, קבוצת צהרון תאוחד עם קבוצת צהרון אחרת לאחד הגנים/כיתות סמוכים (בגבולות מס' הילדים המותר) ולהורה לא תהיה כל תלונה או תביעה כלפי העמותה.
            <br><br>
            באם לא תהיה קבוצה לאיחוד – לא תיפתח הקבוצה, ולהורה לא תהיה כל תלונה כלפי העמותה.
            <br><br>
            • הצדדים מסכימים ומבינים את כל האמור בחוזה זה וחותמים מרצונם הטוב והחופשי על טופס ההרשמה.
            <br><br>
            • ייתכנו שינויים בהסכם זה בהתאם להנחיות/הסכמות שיקבעו ע"י הרשות, משרד החינוך, המשרד לשירותים חברתיים, או מדינת ישראל.
            `,
eliad_bneiShimon_terms_approval:`
            כתב התחייבות צהרון עמותת אליעד (להלן: "העמותה")
            <br><br>
            בתי ספר/גני ילדים - תשפ"ה
            <br><br>
            מתחייבים בזאת ביחד ולחוד כלפי הנהלת העמותה כדלקמן:
            <br><br>
            1.	לשלם את שכר הלימוד החודשי (להלן: "שכר הלימוד").
            <br><br>
            2.	לשלם במועד שייקבע על-ידי הנהלת העמותה את שכר הלימוד עבור הילד/ה.
            <br><br>
            3.	אנו מתחייבים לשלם את שכר הלימוד החל מחודש הצטרפות הילד לצהרון באמצעות הוראת קבע בנקאית.
            <br><br>
            4.	אנו מתחייבים בזאת שלא לבטל את אמצעי התשלום עד לסיום תשלום מלוא שכר הלימוד.
            <br><br>
            5.	בכל מקרה של פיגור בתשלום סכום כלשהו עפ"י כתב התחייבות זה העולה על שבעה ימים, אנו מתחייבים לשלם לכם הפרשי הצמדה וריבת פיגורים בשיעור הנהוג בבנה"פ על חריגות בחשבונות עו"ש דביטורי וזאת מבלי לגרוע מיתר סעדי עפ"י כתב  התחייבות ו\או עפ"י כל דין. 
            <br><br>
            6.	בנוסף לאמור בסעיף לעיל, אנו מתחייבים לשלם להנהלת העמותה, את כל ההוצאות בקשר לאי תשלום סכום כלשהו עפ"י כתב התחייבות זה במלואו ובמועדו כולל שכ"ט עו"ד. בגין ביטול הוראה קבע נחויב  בסך של 68 ₪,  בגין ביטול חוזר של הוראת קבע נחויב בסך של 100 ₪.
            <br><br>
            7.	הייתה גבייתו של תשלום כלשהו המגיע להנהלת העמותה כרוכה בנקיטת הליכים משפטיים, אנו מתחייבים לשלם את הוצאות המשפט ושכ"ט עו"ד, כפי שיפסקו בפסק דין. 
            <br><br>
            8.	ידוע לנו כי הנהלת הצהרון תהא רשאית להפסיק ביקורו של ילד בצהרון בכל מקרה של פיגור בתשלום כלשהו, עפ"י כתב התחייבות זה וזאת לאחר, מתן התראה בכתב של 14 יום מראש, במהלכם לא נפרע חוב שכר הלימוד, והכל מבלי לגרוע מיתר סעדיה עפ"י כתב ההתחייבות ועפ"י כל דין.
            <br><br>
            עוד הוסכם בין הצדדים כדלקמן :
            <br><br>
            9.	העמותה מקבלת על עצמה בזה להפעיל את הצהרון וההורים מתחייבים לקבל עליהם להסכים לתנאים להפעלתו. 
            <br><br>
            10.	הצהרונים יפעלו בגני הילדים/בתי הספר של היישוב/המועצה.
            <br><br>
            11.	העמותה תהיה מוסמכת לקבל כספים עבור הצהרון על מנת לבצע את כל הפעולות האדמיניסטרטיביות של הצהרון, להחליט על אופן הפעלתו, פתיחתו, סגירתו ו/או הפסקת השתתפות של מי מהילדים בו.
            <br><br>
            12.	ההורים מאשרים לעמותה לצלם את הילד ולפרסם את תמונתו בקבוצות ובאתר העמותה.
            <br><br>
            13.	זמני פעילות הצהרון: 
            <br><br>
            א.	הצהרון ייפתח החל מיום ה- 01.09.2024 ועד ליום ה - 30.06.2025.
            <br><br>
            ב.	הצהרון יפעל בימים א'–ה'.
            <br><br>
            ג.	לילדי ט"ט-חובה, פעילות הצהרון תחל לאחר ימי ההסתגלות. למען התנהלות תקינה בצהרון, אין אפשרות לנוכחות הורים בצהרון.
            <br><br>
            ד.	במהלך השנה אפשר להצטרף על בסיס מקום פנוי ב-1 בחודש או ב-15 בחודש. 
            <br><br>
            14.	בחופשות של משרד החינוך, תתקיים הפעילות גם בשעות הבוקר
            שעות הפעילות בימים אלו: 8:00-16:00.
            א.	בימי חנוכה ופסח. 
            ב.	עבור ימים אלו לא תתבצע גבייה נוספת. סכום הגבייה יעודכן סמוך למועד הפעילות. 
            15.	הצהרון לא יפעל בימים שיש בהם מסיבה בגן, אבל לא יותר משתי מסיבות בשנה.
            <br><br>
            16.	בימים בהם גן הבוקר מסתיים מוקדם, הצהרון יחל משעת סיום הגן ויסתיים מוקדם יותר בהתאמה.
            <br><br>
            17.	בימי שביתה לא מתקיים צהרון.
            <br><br>
            18.	במקרה של ביטול הצהרון עקב הנחיות פיקוד העורף/ הרשות - לא יינתן זיכוי.
            <br><br>
            19.	הצהרון כולל ארוחת צהרים חמה ביתית, הצהרון אינו מתחייב לסידורי דיאטה מיוחדים. (אין להביא ארוחת צהרים מהבית, למעט באם יש סיבה רפואית ומוכחת באישור רפואי מתאים). 
            <br><br>
            20.	מתקיימת העשרה, ותוכנית פדגוגית חודשית. 
            <br><br>
            21.	עלות הצהרון: 
            <br><br>
            א.	עלות  הצהרון 935 ₪ לחודש. 
            <br><br>
            ב.	ההורים מתחייבים להסדיר את תשלום דמי ההשתתפות בצהרון בעת ההרשמה, מראש עבור כל השנה ב-10 תשלומים חודשיים כמקובל בצהרונים. לא יתבצע רישום ללא הסדרת תשלום מראש לכל השנה. 
            <br><br>
            22.	רישום מאוחר בהתאם לאמור בסעיף 13(ד) לעיל – ייגבה מההורים מחצית מהסכום האמור בסעיף 21(א) באם הרישום היה בתאריך 15 לחודש.
            <br><br>
            23.	ההורים מאשרים ויתור סודיות מול צוות הבוקר בנוגע לילדם. 
            <br><br>
            24.	השתתפות ילד אלרגי, שיש לו סייעת צמודה בבוקר, הינה באישור העמותה בלבד. לקבלת האישור יש להגיש בקשה בכתב.
            <br><br>
            25.	ילד חולה – ההורים מתחייבים לא לשלוח ילד חולה לצהרון. 
            <br><br>
            26.	לא תתקבל טענה להחזר כספים בגין ימי מחלה ו\או עקב אירוע משפחתי ו\או כל ביטול הצהרון מסיבות שאינן תלויות בעמותה.
            <br><br>
            27.	לעמותה יש את הזכות להוציא ילד מהצהרון אם ישנה או תתעורר בעיית גמילה חריגה. 
            <br><br>
            28.	ההורים מתחייבים להוציא את הילדים מהפעילות בשעה המיועדת. כל איחור יגרום לתוספת תשלום, על כל איחור של חמש דקות מעבר לשעת הסגירה יגבו 25 ₪  בתוספת לשכר הלימוד החודשי ויועברו ע"י העמותה לצוות באופן אוטומטי.
            <br><br>
            נוהלי ביטול הרשמה בצהרון:
            <br><br>
            29.	ביטול הצהרון יתאפשר רק בהודעה מראש בשליחת מייל לכתובת הבאה :
            office@htz-tech.co.il. יש ליידע מראש את רכז הצהרונים באזור, תוך פירוט הסיבות והנימוקים לבקשה. 
            <br><br>
            30.	יש לוודא קבלת הבקשה. לא תתקבלנה בקשות ביטול בכל דרך אחרת לרבות בטלפון.
            <br><br>
            31.	יובהר, כי שליחת המייל אינה עילה לביטול הרישום, ורק תשובה חיובית מהעמותה הינה הוכחה לביטול הרישום. 
            <br><br>
            32.	במידה והבקשה תאושר הודעת ביטול תיכנס לתוקף לצורך ביטול עלות הצהרון כלהלן:
            <br><br>
            א.	באם הודעת הביטול תימסר בעמותה עד ה-15 בחודש, חיוב תשלום האחרון יהיה עבור אותו חודש. 
            ב.	באם הודעת הביטול תימסר לעמותה לאחר ה-15 בחודש, חיוב התשלום האחרון יהיה עבור החודש העוקב לאותו החודש בו ניתנה הודעת העזיבה (כך לדוג', הודעה שנמסרה ב – 16.9, יחויבו ההורים עבור חודש ספטמבר וגם עבור חודש אוקטובר). 
            ג.	תינתן אפשרות להשתתף בפעילות הצהרון עד תום מועד התשלום.
            33.	החל מ- 30.3.2025 ואילך לא יאושרו ביטולים או החזרים מכל סיבה שהיא, והמשתתף יחויב בעלות הצהרון עד סוף חודש יוני. 
            <br><br>
            34.	העמותה שומרת לעצמה את הזכות להוציא ילד מפעילות עקב התנהגות לא הולמת או אי עמידה בתשלומים.
            <br><br>
            • קיום הצהרון מותנה במינימום נרשמים.
            <br><br>
            • ייתכן וברישום מינימום, קבוצת צהרון תאוחד עם קבוצת צהרון אחרת לאחד הגנים/כיתות סמוכים (בגבולות מס' הילדים המותר) ולהורה לא תהיה כל תלונה או תביעה כלפי העמותה.
            <br><br>
            באם לא תהיה קבוצה לאיחוד – לא תיפתח הקבוצה, ולהורה לא תהיה כל תלונה כלפי העמותה.
            <br><br>
            • הצדדים מסכימים ומבינים את כל האמור בחוזה זה וחותמים מרצונם הטוב והחופשי על טופס ההרשמה.
            <br><br>
            • ייתכנו שינויים בהסכם זה בהתאם להנחיות/הסכמות שיקבעו ע"י הרשות, משרד החינוך, המשרד לשירותים חברתיים, או מדינת ישראל.
            `,
eliad_hok_Text:`
            1. קוד מוסד 26094- עמותת אליעד
            <br><br>
            2. במידה ותרצו לשלם קייטנות מהרשאה זו - אין להגבילה בסכום
            <br><br>
            3. בתהליך ההקמה בבנק אם נדרשתם למס' אסמכתא - יש למלא ת.ז של אחד מבעלי החשבון`,

lod_terms_approval:`<h2>נוהל צהרונים "ניצנים " – עיריית לוד</h2>
    <h3>שנה"ל תשפ"ה</h3>
    <p>1. עיריית לוד תפעיל צהרונים במוסדות החינוך בעיר עפ"י תוכנית "ניצנים" של משרד החינוך.</p>
    <p>2. מחירי הצהרונים ותנאי התשלום :</p>
    <p>- גני ילדים טרום חובה : 1300 ₪ לשנה (עד 2 תשלומים באשראי / שיקים או תשלום אחד במזומן)</p>
    <p>- גני ילדים חובה (יוח"א) : 800 ₪ לשנה (עד 4 תשלומים באשראי / שיקים או תשלום אחד במזומן)</p>
    <p>- כיתות א- ב : 1300 ₪ לשנה (עד 2 תשלומים באשראי / שיקים או תשלום אחד במזומן)</p>
    <p>- כיתות ג' : 3000 ₪ לשנה (עד 10 תשלומים באשראי / שיקים או תשלום אחד במזומן)</p>
    <p>הממשלה טרם החליטה מה יהיו מחירי הצהרונים בגילאי טרום חובה וכיתות א' – ב' לשנת הלימודים תשפ"ה. ייתכנו שינויים בתעריפים לגילאי טרום חובה וכיתות א' – ב' בהתאם להחלטות הממשלה ומשרד החינוך.</p>
    <p>3. תאריך תחילת צהרון : 01.09.24</p>
    <p>4. פתיחת קבוצה מותנית במספר מינימלי של נרשמים באותו מוסד.</p>
    <p>5. ביטול רישום : ניתן לבטל עד תאריך 31.10.24 לאחר תאריך זה לא ניתן החזר. לבקשות ביטול ניתן לשלוח הודעה כתובה למייל office@htz-tech.co.il או בווטסאפ לטלפון 058-5511320</p>
    <p>6. התשלום לצהרונים הינו שנתי ולא ניתן לשלם על חלק מהתקופה.</p>
    <p>7. אין הנחות אחים או הנחות אחרות.</p>
    <p>8. העירייה רשאית לבטל רישום בגין איחור / עיכוב בתשלום לאחר יומיים התראה.</p>
    <p>9. ילדים אלרגיים : חובה על ההורים לילדים אלרגיים לעדכן באתר הרישום על האלרגיה של ילדם ולצרף אישור מרופא אולרגולוג ללא מילוי חובות אלו בעת הרישום , לא יתאפשר לילד לקבל הזנה בצהרון.</p>
    <p>10. על ההורים לאסוף ילד שאינו חש בטוב מיד עם קבלת דרישה מצוות הצהרון.</p>
    <p>11. אסורה הכנסת מזון שאינו מחברת הקייטרינג המורשת.</p>
    <p>12. הוצאת מזון מהגן בשעות הצהרון :</p>
    <p>א. חל איסור על הוצאת מזון מהגן במהלך שעות פעילות הצהרון.</p>
    <p>ב. במקרה שהילד הרשום לצהרון נאלץ לעזוב מוקדם מהמתוכנן, לא תתאפשר הוצאת מנת המזון המיועדת לו מחוץ לגן.</p>
    <p>ג. זכאות הילד למנת המזון היומית מותנית בנוכחותו בצהרון בזמן הארוחה.</p>
    <p>13. העירייה רשאית להפסיק פעילות במקרי קיצון (מגפה, מלחמה, וכו'). החזרים יינתנו בהתאם להנחיות משרד החינוך.</p>
    <p>14. העירייה רשאית להחליט כי הילד/ה אינו/ה יכול/ה להשתתף במסגרת הצהרון בשל בעיה התנהגותית. זאת עפ"י שיקול דעת מקצועי של הצוות החינוכי . בהתאם לכך, הרשות רשאית להפסיק את השתתפותו של הילד/ה בצהרון, וזאת לאחר מתן התראה של 14 ימים מראש להורים. במקרה זה , תופסק גביית התשלומים מההורים בגין השתתפות בצהרון, החל ממועד הפסקת ההשתתפות ואילך.</p>
    <p>15. העירייה רשאית לערוך שינויים בצוות החינוכי של הצהרון עפ"י צרכי המערכת.</p>
    <p>16. ההורה מתחייב להודיע מראש ובכתב לצוות הצהרון, במקרה שילדו יילקח מהצהרון ע"י אדם אחר שלא הצהיר עליו בטופס הרישום.</p>
    <p>17. לא יאושר איסוף ילד מהצהרון על ידי מי שגילו נמוך מ-12 שנים. באחריות ההורה לוודא כי מי שנשלח מטעמו לאסוף את ילדו עומד בתנאי זה.</p>
    <p>18. ההורים מתחייבים לאסוף את הילד מהצהרון, לא יאוחר משעת סיום הצהרון ומתחייבים לדייק. ידוע להורים כי איחורים חוזרים ונשנים עלולים להוביל להוצאת הילד מן הצהרון, בהתאם לשיקולי ומדיניות הנהלת הצהרונים ברשות.</p>
    <br><br>
            `,
formIntroduction:`
                           הורים יקרים שלום וברכה, 
                           <br><br>
  שמחים שבחרתם לרשום את ילדכם לצהרוני עמותת אליעד. אנא מלאו את הטופס בצורה מלאה ובדקו כי כל הפרטים שאתם מכניסים נכונים. 
  <br><br>
  <span style="color: red;">במהלך מילוי הטופס יש לצרף הרשאה לחיוב חשבון לצורך החיובים השוטפים לאורך שנת הלימודים.</span>
  <br><br>
  קוד המוסד להקמת הרשאה הוא 26094 - עמותת אליעד (לנוחיותכם, אנא הקימו את ההרשאה לפני תחילת הרישום ושמרו את האסמכתא במכשירכם לצורך העלאת הקובץ והשלמת הרישום) 
  ילדים ממשיכים ששילמו בשנה הקודמת לעמותת אליעד, בידקו שאין תאריך תוקף להרשאה והעלו את ההרשאה הקיימת. 
  כמו כן מצורף כתב התחייבות-אנא קראו בעיון. 
  מאחלים שנה טובה ומבורכת
            `,

  };
  
  export default terms_approval;
  