import React, { useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { useLocation, useParams } from 'react-router-dom';
import appConfig from '../../utils/appConfig';

interface PaymentResponse {
    success: boolean;
    code: string;
    userId: string;
    sum: number;
    email: string;
    confirmationCode: string;
}  

// פונקציה שמטפלת בתגובת התשלום מטרנזילה
function handlePaymentResponse(event: MessageEvent) {
  if (event.origin !== 'https://direct.tranzila.com') {
    // התעלם מהודעות שמגיעות ממקורות לא מוכרים
    return;
  }

  console.log('Received message from Tranzila:', event.data);

  try {
    const response: PaymentResponse = JSON.parse(event.data);
    console.log('Parsed payment response:', response);

    // שליחת התוצאה לשרת באמצעות POST
    sendPaymentResult(response);

  } catch (error) {
    console.error('Error parsing payment response:', error);
  }
}

// דוגמה לפונקציה מטרנזילה ששולחת בקשת POST
function sendPaymentResult(response: PaymentResponse) {
    fetch('https://afternoon.billagan.co.il/api/tranzilaCallback', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(response)
    }).then(res => res.json())
      .then(data => console.log('Success:', data))
      .catch((error) => console.error('Error:', error));
}

const CreditcardPay: React.FC = () => {
  const location = useLocation();
  const afternoon = location.state?.afternoon;
  const formData = location.state?.formData;

  const calculateMonthsToJune = () => {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth(); // מחזיר את החודש הנוכחי כמספר (0-11)
    const currentYear = currentDate.getFullYear();

    // מספר החודשים עד יוני הבא כולל החודש הנוכחי ויוני הבא
    const juneMonth = 5; // 5 מייצג את יוני (0-11)
    const juneYear = currentMonth <= juneMonth ? currentYear : currentYear + 1;

    // חישוב מספר החודשים
    let monthsDifference = (juneYear - currentYear) * 12 + (juneMonth - currentMonth) + 1;

    monthsDifference = monthsDifference > 10 ? 10 : monthsDifference;

    return monthsDifference;
  };

  const { authorityName } = useParams<{ authorityName: string }>();

  const authority = appConfig.authorities.find(auth => auth.en === authorityName);

  const mounts = calculateMonthsToJune();
  // const sum = afternoon.field22  ?(afternoon.field22 * mounts):afternoon.Total_annual_price;
  const recur_payments =8;//מספר תשלומים, חוץ מהתשלום הראשון
  const sum = afternoon.field22  ?((afternoon.field22*10)/(recur_payments+1)):afternoon.Total_annual_price/(recur_payments+1);//סכום תשלום ראשון
  
  //const recur_sum = afternoon.field22  ?(afternoon.field22*((recur_payments+2)/(recur_payments+1))) :afternoon.Total_annual_price/recur_payments ;//סכום שאר תשלומים
  const recur_sum=sum
  const supplier = authority?.tranzilaCodeTok; // יש להחליף לפי שם המסוף שלך
  const currency = 1;
  const email = formData.parents.email;
  const lang = "il";
  const recur_transaction="4_approved";
  const contact = `${formData.parents.parentName} ${formData.lastName}`;
  const ID_child = formData.studentId;
  const recur_start_date = "2024-10-10";
  const user_defined_3 = formData.parents.email;
  const user_defined_7 = ` תשלום עבור צהרון - ${formData.firstName} ${formData.lastName}`;
  const user_defined_8 = formData.studentId;
  const pdesc= `תשלום עבור צהרון - ${formData.firstName} ${formData.lastName}`;
  const type = "צהרון";
  const hidesum=1;
  const thankYouUrl = appConfig.apiUrls.baseUrl?.replace("/api", "") + "/thankYouPage";
  console.log(thankYouUrl);

  const iframeUrl = `https://direct.tranzila.com/${supplier}/iframenew.php?&currency=${currency}&recur_transaction=${recur_transaction}&recur_payments=${recur_payments}&recur_start_date=${recur_start_date}&lang=${lang}
                     &user_defined_3=${user_defined_3}&user_defined_7=${user_defined_7}&user_defined_8=${user_defined_8}&email=${email}&pdesc=${pdesc}&sum=${sum}&cred_type=1&recur_sum=${recur_sum}
                     &contact=${contact}&ID_child=${ID_child}&type=${type}&success_url_address=${thankYouUrl}&fail_url_address=${thankYouUrl}&nologo=1`;

  useEffect(() => {
    window.addEventListener('message', handlePaymentResponse);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('message', handlePaymentResponse);
    };
  }, []);

  return (
    <Box sx={{ maxWidth: 400, margin: '0 auto', padding: 2 }}>
      <Typography
        variant="h6"
        sx={{
          marginBottom: 2,
          textAlign: 'center',
          padding: 2,
          backgroundColor: '#f0f4f8',
          borderRadius: '8px',
          boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
          border: '1px solid #ddd',
        }}
      >
        <span style={{ fontWeight: 'bold', color: '#333' }}>שם הילד:</span> {formData.firstName}
        <br />
        <span style={{ fontWeight: 'bold', color: '#333' }}>שם המוסד:</span> {afternoon.Name}
        <br />
        <span style={{ fontWeight: 'bold', color: '#333' }}>סכום לתשלום:</span> ₪{sum*(recur_payments+1)}
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }}>
        <iframe
          src={iframeUrl}
          width="370"
          height="455"
          frameBorder="0"
          scrolling="no"
          title="Tranzila Payment"
          style={{ border: '1px solid #ddd', borderRadius: '8px' }}
        ></iframe>
      </Box>
    </Box>
  );
};

export default CreditcardPay;