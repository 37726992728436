import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import axios from 'axios';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { Autocomplete, Box, Button, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, TextField, useTheme } from '@mui/material';
import './Afternoons.css';
import appConfig from '../../utils/appConfig';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import terms_approval from '../../utils/termsAproval';

interface Afternoon {
  id: string;
  Name: string;
  Afternoon_Dates: string;
  field22: string;
  Afternoon_snif: string;
  Afternoon_Hours: string;
  field13: {
    if_pay_hok_bank: boolean;
  };
}


const Afternoons: React.FC = () => {
  const { accountId } = useParams<{ accountId: string }>();
  const navigate = useNavigate();
  const location = useLocation();
  const account = location.state?.account;
  const { accounts } = location.state || { accounts: [] };

  const [afternoons, setAfternoons] = useState<Afternoon[]>([]);
  const [filter, setFilter] = useState<string>("");
  const [snifOptions, setSnifOptions] = useState<string[]>([]);
  const [isMobile, setIsMobile] = useState<boolean>(appConfig.is_mobile);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [selectedAfternoon, setSelectedAfternoon] = useState<Afternoon | null>(null);
  const [openWarningDialog, setOpenWarningDialog] = useState<boolean>(false);
  const [payHokBank, setPayHokBank] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  

  const theme = useTheme();

  const { authorityName } = useParams<{ authorityName: string }>();

  const authority = appConfig.authorities.find(auth => auth.en === authorityName);

  const handleOpenDialog = (afternoon: Afternoon) => {
    setSelectedAfternoon(afternoon);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedAfternoon(null);
  };

  const handleOpenWarningDialog = (afternoon: Afternoon) => {

    if (payHokBank) {
      setSelectedAfternoon(afternoon);
      setOpenWarningDialog(true);
    } else {
      navigate(`/${authority?.en}/form/${afternoon.id}`, { state: { account, afternoon } });
    }
  };
  

  const handleCloseWarningDialog = () => {
    setOpenWarningDialog(false);
  };

  const handleContinueRegistration = () => {
    if (selectedAfternoon) {
      navigate(`/${authority?.en}/form/${selectedAfternoon.id}`, { state: { account, afternoon: selectedAfternoon } });
    }
    setOpenWarningDialog(false);
  };


  useEffect(() => {
    const fetchAfternoons = async () => {
      try {
        const response = await axios.get(`${appConfig.apiUrls.baseUrl}/zohoMosads/getAllAfternoons/${accountId}`);
        setAfternoons(response.data);
        setPayHokBank(response.data[0]["field13.if_pay_hok_bank"]);

        const snifSet: Set<string> = new Set(response.data.map((afternoon: Afternoon) => afternoon.Afternoon_snif));
        const sortedSnifOptions = Array.from(snifSet).sort((a, b) => a.localeCompare(b, 'he', { sensitivity: 'base' }));
        setSnifOptions(sortedSnifOptions);
      } catch (error) {
        console.error('Error fetching afternoons data:', error);
      }
    };

    fetchAfternoons();
  }, [accountId]);
  
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 600);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleSnifChange = (event: SelectChangeEvent<string>) => {
    const value = event.target.value;
    setFilter(value === "" ? "" : value);
  };
  const handleSearchChange = (event: React.SyntheticEvent, value: string | null) => {
    setSearchValue(value ?? '');
  };

  const filteredAfternoons = filter ? afternoons.filter(afternoon => afternoon.Afternoon_snif === filter) : afternoons;

  const commonColumns: GridColDef[] = [
    {
      field: 'register',
      headerName: '',
      renderCell: (params) => (
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleOpenWarningDialog(params.row as Afternoon)}
          sx={{ fontSize: '0.75rem', padding: '4px 8px', minWidth: 'auto' }}
        >
          הרשם
        </Button>
      ),
      flex: 1,
      align: 'center',
      headerAlign: 'right',
      width: 150,
    },
    {
      field: 'Name',
      headerName: 'שם מוסד',
      flex: 2, // עמודה רחבה יותר
      align: 'right',
      headerAlign: 'right',
      renderHeader: (params) => (
        <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', direction: 'rtl' }}>
          שם מוסד
        </div>
      ),
      renderCell: (params) => (
        <div style={{ direction: 'rtl', textAlign: 'right', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {params.value}
        </div>
      )
    }
  ];
  
  const desktopColumns: GridColDef[] = [
    {
      field: 'field22',
      headerName: 'מחיר חודשי',
      flex: 0.6, // עמודה רגילה
      align: 'left',
      headerAlign: 'right',
      renderHeader: (params) => (
        <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', direction: 'rtl' ,textAlign: 'left',}}>
          מחיר חודשי
        </div>
      ),
    },
    {
      field: 'Afternoon_Hours',
      headerName: 'שעות הפעילות',
      flex: 1, // עמודה רגילה
      align: 'right',
      headerAlign: 'right',
      renderHeader: (params) => (
        <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', direction: 'rtl' }}>
          שעות הפעילות
        </div>
      ),
      renderCell: (params) => (
        <div style={{ direction: 'rtl', textAlign: 'right', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {params.value}
        </div>
      )
    },
    {
      field: 'Afternoon_Dates',
      headerName: 'תאריכי הפעילות',
      flex: 1, // עמודה רגילה
      align: 'right',
      headerAlign: 'right',
      renderHeader: (params) => (
        <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', direction: 'rtl' }}>
          תאריכי הפעילות
        </div>
      ),
      renderCell: (params) => (
        <div style={{ direction: 'rtl', textAlign: 'right', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {params.value}
        </div>
      )
    }
  ];
  
  const mobileColumns: GridColDef[] = [
    {
      field: 'activityDescription',
      headerName: '',
      flex: 1, // עמודה רגילה
      align: 'right',
      headerAlign: 'right',
      width: 80,
      renderCell: (params: GridRenderCellParams) => (
        <Button
          variant="text"
          style={{ color: 'blue', fontSize: '14px', textAlign:'center' }}
          onClick={() => handleOpenDialog(params.row as Afternoon)}
        >
          לפרטים
        </Button>
      )
    }
  ];
  
  const columns = isMobile
    ? [...commonColumns.slice(0, 1), ...mobileColumns, commonColumns[1]]
    : [...commonColumns.slice(0, 1), ...desktopColumns, commonColumns[1]];

  
  

    const ActivityDialog: React.FC<{ open: boolean, afternoon: Afternoon | null, onClose: () => void }> = ({ open, afternoon, onClose }) => (
      <Dialog open={open} onClose={onClose} aria-labelledby="activity-dialog-title">
        <DialogTitle id="activity-dialog-title" style={{ direction: 'rtl' }}>תיאור הפעילות</DialogTitle>
        <DialogContent style={{ direction: 'rtl', textAlign: 'right' }}>
          {afternoon && (
            <>
            <div style={{ marginBottom: '10px' }}>
                <strong>שם המוסד:</strong><br />
                <span>{afternoon.Name}</span>
              </div>
              <div style={{ marginBottom: '10px' }}>
                <strong>תאריכי הפעילות:</strong><br />
                <span>{afternoon.Afternoon_Dates}</span>
              </div>
              <div style={{ marginBottom: '10px' }}>
                <strong>שעות הפעילות:</strong><br />
                <span>{afternoon.Afternoon_Hours}</span>
              </div>
              <div style={{ marginBottom: '10px' }}>
                <strong>מחיר לחודש:</strong><br />
                <span>{afternoon.field22}</span>
              </div>
            </>
          )}
        </DialogContent>
        <DialogActions style={{ justifyContent: 'center' }}>
      
      {afternoon && (
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleOpenWarningDialog(afternoon)}
          sx={{ fontSize: '0.75rem', padding: '4px 8px', minWidth: 'auto', marginLeft: '10px'  }}
        >
          הרשם
        </Button>
      )}
      <Button onClick={onClose} color="primary"
      sx={{
        fontSize: '0.75rem', padding: '4px 8px', minWidth: 'auto' ,
          backgroundColor: 'red',
          color: 'white',
          fontWeight: 'bold',
         
          '&:hover': {
            backgroundColor: '#660018',
          },
        }}
        autoFocus
      >
            חזור 
          </Button>
    </DialogActions>
      </Dialog>
    );

    const RegistrationWarningDialog: React.FC<{ open: boolean, onClose: () => void, onContinue: () => void }> = ({ open, onClose, onContinue }) => (
      <Dialog open={open} onClose={onClose} aria-labelledby="warning-dialog-title">
        <DialogContent style={{ direction: 'rtl', textAlign: 'right' }}>
        <div style={{ textAlign: 'right', direction: 'rtl' }} dangerouslySetInnerHTML={{ __html: terms_approval.formIntroduction }} />
        </DialogContent>
        <DialogActions style={{ justifyContent: 'space-between' }}>
        <Button 
        onClick={onContinue}
        sx={{
          backgroundColor: '#4caf50',
          color: 'white',
          fontWeight: 'bold',
          padding: '8px 16px',
          '&:hover': {
            backgroundColor: '#388e3c',
          },
        }}
        autoFocus
      >
        המשך תהליך
          </Button>
          <Button 
        onClick={onContinue}
        sx={{
          backgroundColor: 'red',
          color: 'white',
          fontWeight: 'bold',
          padding: '8px 16px',
          '&:hover': {
            backgroundColor: '#660018',
          },
        }}
        autoFocus
      >
            חזור 
          </Button>
        </DialogActions>
      </Dialog>
    );

    const filteredOptions = afternoons.filter(afternoon =>
      afternoon.Name.toLowerCase().startsWith(searchValue.toLowerCase())
    ).map(afternoon => afternoon.Name);

    return (
      <div className="afternoons-container">
        <h1>
  
        </h1>
        <div className="filter-container" style={{ textAlign: 'center', width: '300px', whiteSpace: 'nowrap' }}>
        {accounts?.length === 1 ? (
        <Autocomplete
        freeSolo
        options={filteredOptions}
        value={searchValue}
        onChange={(event, newValue) => {
          setSearchValue(newValue || '');
          setSearchTerm(newValue || '');
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="חיפוש מוסד"
            variant="outlined"
            fullWidth
            onChange={(e) => setSearchTerm(e.target.value)}
            inputProps={{
              ...params.inputProps,
              style: { textAlign: 'right', direction: 'rtl' },
            }}
          />
        )}
        getOptionLabel={(option) => option}
        renderOption={(props, option) => (
          <li {...props} style={{ textAlign: 'right', direction: 'rtl' }}>
            {option}
          </li>
        )}
      />
      ) : (
        <FormControl variant="outlined" fullWidth>
          <InputLabel htmlFor="snifFilter">בחרו מוסד</InputLabel>
          <Select
            labelId="snifFilterLabel"
            id="snifFilter"
            value={filter}
            onChange={handleSnifChange}
            label="סנן לפי סניף"
          >
            <MenuItem value="" style={{ textAlign: 'right', direction: 'rtl' }}>
              <em>הכל</em>
            </MenuItem>
            {snifOptions.map((snif, index) => (
              <MenuItem
                key={index}
                value={snif}
                style={{ textAlign: 'right', direction: 'rtl', maxWidth: '300px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
              >
                {snif}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
        </div>
        <Box sx={{ height: 400, width: '100%' }}>
      <DataGrid
        rows={filteredAfternoons}
        columns={columns}
        getRowId={(row) => row.id}
        initialState={{
          sorting: {
            sortModel: [{ field: 'Name', sort: 'asc' }],
          },
        }}
        onRowClick={(params) => {
          if (isMobile) {
            handleOpenDialog(params.row);
          }
        }}
        sx={{
          '& .MuiDataGrid-root': {
            fontFamily: theme.typography.body1.fontFamily,
            fontSize: theme.typography.body1.fontSize,
          },
          '& .MuiDataGrid-cell': {
            fontFamily: theme.typography.body1.fontFamily,
            fontSize: theme.typography.body1.fontSize,
          },
          '& .MuiDataGrid-columnHeader': {
            fontFamily: theme.typography.body1.fontFamily,
            fontSize: theme.typography.body1.fontSize,
          },
        }}
      />
    </Box>

        <ActivityDialog open={openDialog} afternoon={selectedAfternoon} onClose={handleCloseDialog} />
        <RegistrationWarningDialog open={openWarningDialog} onClose={handleCloseWarningDialog} onContinue={handleContinueRegistration} />
      </div>
    );
    

  
};

export default Afternoons;
